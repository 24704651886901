/* compiled from /scss/_modules.scss */

@charset "UTF-8";





@import "variables";
@import "mixins";
@import "flexbox";



footer {
  background-color: $black;
  padding: 0 $ps;
  @include mq(tbl) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @include mq(pc) {
    padding-left: 0;
    padding-right: 0;
  }
  .inner {
    padding: 25px 0;
    color: white;
    position: relative;
    @include mq(tb) {
      padding: 30px 0;
    }
    @include mq(tbl) {
      padding: 60px 0;
    }
    @include mq(pc) {
      @include container;
    }
    #pageTop {
      position: absolute;
      right: 0;
      top: -20px;
      @include mq(tbl) {
        top: -32px;
      }
      a {
        background-color: $main;
        width: 40px;
        height: 40px;
        display: block;
        padding-top: 10px;
        position: relative;
        @include mq(tbl) {
          width: 64px;
          height: 64px;
          padding-top: 15px;
          transition: padding .2s ease;
        }
        &:before {
          content: '';
          background-color: $black;
          display: block;
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          bottom: 0;
          transition: height .4s ease-in-out;
        }
        &:hover {
          @include mq(tbl) {
            &:before {
              height: 100%;
            }
          }
        }
        span {
          width: 8px;
          height: 17px;
          background-image: url(/images/pagetop.png);
          background-size: contain;
          background-position: 0 0;
          display: block;
          text-indent: 100%;
          white-space: nowrap;
          overflow: hidden;
          margin: 0 auto;
          position: relative;
          z-index: 2;
          @include mq(tbl) {
            width: 16px;
            height: 34px;
            background-image: url(/images/pagetop_pc.png);
          }
        }
      }
    }
    .info {
      text-align: center;
      @include mq(pc) {
        text-align: left;
        @include flexbox;
        @include justify-content(space-between);
      }
      .logo {
        @include mq(pc) {
          width: 185px;
          padding-top: 6px;
        }
        .tagline {
          @include fs(9,9);
          transform: scale(.8);
          @include mq(tbl) {
            @include fs(12,12);
            letter-spacing: 1px;
            text-align: center;
            transform: scale(1);
          }
          @include mq(pc) {
            text-align: left;
          }
        }
        .image {
          width: 78.5px;
          height: 26.5px;
          background-image: url(/images/logo_footer.png);
          background-position: 0 0;
          background-size: contain;
          display: block;
          text-indent: 100%;
          white-space: nowrap;
          overflow: hidden;
          margin: 3px auto 0;
          @include mq(tbl) {
            width: 133px;
            height: 45px;
            background-image: url(/images/logo_footer_pc.png);
            margin: 13px auto 0;
          }
          @include mq(pc) {
            margin-left: 0;
          }
        }
      }
      aside {
        margin-top: 25px;
        @include mq(pc) {
          margin-top: 0;
          @include flexbox;
          @include justify-content(space-between);
          @include flex(1);
          align-items: flex-end;
        }
        address {
          @include fs(11,18);
          letter-spacing: 1px;
          @include mq(tb) {
            @include fs(12,22);
          }
          @include mq(tbl) {
            @include fs(14,30);
          }
          h2 {
            @include jaBold;
            @include mq(tbl) {
              @include fs(15,30);
              margin-bottom: 1em;
            }
          }
        }
        .right {
          .sns {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            @include mq(pc) {
              justify-content: flex-end;
              margin-top: 0;
            }
            li {
              margin: 0 15px;
              @include mq(pc) {
                margin: 0 10px;
                &:last-child {
                  margin-right: 0;
                }
              }
              a {
                @include hoverOp;
                &.twitter {
                  img {
                    width: 23px;
                    height: auto;
                  }
                }
                &.note {
                  img {
                    width: 19px;
                    height: auto;
                  }
                }
              }
            }
          }
          .privacy {
            margin-top: 28px;
            @include fs(10,18);
            letter-spacing: .05em;
            @include mq(tbl) {
              @include fs(12,20);
            }
            @include mq(pc) {
              text-align: right;
            }
            a {
              color: white;
              @include mq(tbl) {
                &:hover {
                  color: $main;
                }
              }
            }
          }
          .copyright {
            margin-top: 25px;
            @include en;
            letter-spacing: 1px;
            @include fs(10,18);
            @include mq(tb) {
              @include fs(11,20);
            }
            @include mq(pc) {
              text-align: right;
              
              @include align-self(flex-end);
            }
            a {
              transition: color .2s ease;
              color: white;
              @include mq(tbl) {
                &:hover {
                  color: $main;
                }
              }
            }
          }
        }
      }
    }
  }
}
