@charset "UTF-8";

@import "reset";
@import "variables";
@import "mixins";
@import "flexbox";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;600&family=Roboto:wght@300;400;700&display=swap');

* {
   box-sizing: border-box;
}
*:before,
*:after {
   box-sizing: border-box;
}
*:focus {
outline: none;
}
html {
  font-size: 62.5%;
  height: 100%;
  @include ja;
  @include mq(pc) {
    min-width: $container;
  }
}
body {
  @include fs(14);
  color: $black;
  background-color: white;
  -webkit-text-size-adjust: 100%;
  @include ja;
  padding-top: $pt;
  @include mq(tbl) {
    padding-top: 0;
  }
  &.fixed {
    position: fixed;
    @include mq(tbl) {
      position: static;
    }
  }
  &:after {
    //content: 'sp';
    position: fixed;
    left: 10px;
    top: 10px;
    font-size: 20px;
    background-color: red;
    color: white;
    z-index: 1000;
    @include en;
    padding: 5px;
    @include fs(12,12);
    text-transform: uppercase;
    @include mq(tb) {
      //content: 'tb';
    }
    @include mq(tbl) {
      //content: 'tbl';
    }
    @include mq(pc) {
      //content: 'pc';
    }
  }
}
strong {
  font-weight: bold;
}
label {
  cursor: pointer;
}
input,select,textarea,label,a {
  outline: none;
}
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
a {
  color: $black;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    @media (min-width: $screen-sm-min) {
      //text-decoration: underline;
    }
  }
  &[href^="tel:"] {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
}
button {
  outline: none;
  cursor: pointer;
}
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  position: relative;
  font-size: 0.75em;
}
sup {
  bottom: .75ex;
}
sub {
  top: .5ex;
}
img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  @media (min-width: $screen-sm-min) {
    max-width: none;
  }
}
.hidden-over-pc {
  @include mq(pc) {
    display: none;
  }
}
.hidden-over-sp {
  @include mq(sp) {
    display: none;
  }
}
.hidden-over-tb {
  @include mq(tb) {
    display: none;
  }
}
.hidden-over-tbl {
  @include mq(tbl) {
    display: none;
  }
}
.hidden-sp {
  @media (max-width: $screen-sm-min) {
    display: none;
  }
}
.hidden-tb {
  @media (max-width: 768px) {
    display: none;
  }
}
.hidden-tbl {
  @media (max-width: 960px) {
    display: none;
  }
}
.hidden-pc {
  @media (max-width: 1260px) {
    display: none;
  }
}
.container {
  @include container;
}
@import "header";
@import "footer";
@import "modules";
@import "modules_top";
@import "modules_jobs";
@import "modules_team";
@import "modules_news";
@import "modules_about";
@import "modules_contact";
@import "modules_athlete";
@import "lity";
@import "utilities";
