/* compiled from /scss/_modules.scss */

@charset "UTF-8";





@import "variables";
@import "mixins";
@import "flexbox";



.m_top_doctor {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    padding: 0 $ps 30px;
    @include mq(pc) {
      @include container;
    }
    .s_button_doctor {
      background-color: #B59402;
      display: block;
      padding: 17px;
      color: $white;
      position: relative;
      max-width: 380px;
      margin-left: auto;
      margin-right: auto;
      @include mq(tb) {
        padding: 22px 20px 25px;
      }
      @include mq(tbl) {
        padding: 30px 20px 30px;
        max-width: 540px;
      }
      &:before {
        content: '';
        background-color: $black;
        display: block;
        position: absolute;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        transition: width .4s ease-in-out;
      }
      &:hover {
        @include mq(tbl) {
          &:before {
            width: 100%;
          }
        }
      }
      strong, span {
        display: block;
        text-align: center;
        position: relative;
        z-index: 2;
        letter-spacing: 1px;
      }
      strong {
        @include jaBold;
        @include fs(17,17);
        @include mq(tb) {
          @include fs(21,21);
        }
        @include mq(tbl) {
          @include fs(26,26);
        }
        i {
          margin-left: 5px;
          @include mq(tb) {
            margin-left: 8px;
          }
          @include mq(tbl) {
            margin-left: 10px;
          }
          img {
            width: 7px;
            transform: translateY(-3px);
            @include mq(tb) {
              transform: translateY(-5px);
            }
            @include mq(tbl) {
              width: 9px;
              transform: translateY(-6px);
            }
          }
        }
      }
      span {
        @include en;
        @include fs(9,9);
        margin-top: 8px;
        @include mq(tb) {
          @include fs(11,11);
        }
        @include mq(tbl) {
          margin-top: 14px;
          @include fs(12,12);
        }
      }
    }
  }
}
.m_top_lead {
  margin-top: 30px;
  padding: 0 $ps;
  text-align: center;
  @include mq(tb) {
    margin-top: 50px;
  }
  @include mq(tbl) {
    margin-top: 100px;
  }
  @include mq(pc) {
    @include container;
  }
  h2 {
    .ja {
      @include jaBold;
      @include fs(24,24);
      display: block;
      letter-spacing: 2px;
      text-indent: 1em;
      @include mq(tb) {
        @include fs(36,36);
        letter-spacing: 6px;
      }
      @include mq(tbl) {
        @include fs(50,50);
        letter-spacing: 10px;
      }
      span {
        color: $main;
      }
    }
    .en {
      margin-top: 1.2em;
      @include en;
      @include fs(10,10);
      display: block;
      letter-spacing: 2px;
      text-indent: 1em;
      @include mq(tb) {
        @include fs(15,15);
        letter-spacing: 3px;
      }
      @include mq(tbl) {
        @include fs(22,22);
        letter-spacing: 5px;
      }
      span {
        color: $main;
      }
    }
  }
  .lead {
    margin-top: 30px;
    @include mq(tb) {
      margin-top: 50px;
    }
    @include mq(tbl) {
      margin-top: 90px;
    }
    .ja {
      @include fs(13,28);
      padding-bottom: 1.2em;
      @include mq(tb) {
        @include fs(14,30);
      }
      @include mq(tbl) {
        @include fs(15,36);
      }
    }
    .en {
      @include en;
      @include fs(12,28);
      padding-bottom: 1.2em;
      @include mq(tb) {
        @include fs(13,30);
      }
      @include mq(tbl) {
        @include fs(14,36);
      }
    }
  }
  .s_button {
    margin-top: 20px;
    @include mq(tb) {
      margin-top: 40px;
    }
    @include mq(tbl) {
      margin-top: 50px;
    }
  }
}
.m_top_doors {
  margin-top: 50px;
  padding: 0 $ps;
  text-align: center;
  @include mq(tb) {
    margin-top: 70px;
  }
  @include mq(tbl) {
    margin-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
  }
  @include mq(pc) {
  }
  @include mq(pcl) {
    @include container;
    padding: 0;
    width: $container + 140px;
  }
  .inner {
    @include mq(tbl) {
      @include flexbox;
    }
    .image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 42vw;
      margin: 0 auto;
      @include mq(tbl) {
        @include flex(1);
        height: auto;
      }
      @include mq(pc) {
        @include flex(0 1 auto);
        width: 580px;
      }
    }
    .text {
      margin-top: 30px;
      @include mq(tb) {
        margin-top: 50px;
      }
      @include mq(tbl) {
        @include flex(1);
        padding-left: 50px;
        margin-top: 0;
        text-align: right;
      }
      @include mq(pc) {
        padding-right: 75px;
      }
      h2 {
        .en {
          @include en;
          @include fs(12,18);
          display: block;
          letter-spacing: 1px;
          color: $main;
          @include mq(tb) {
            @include fs(14,20);
          }
          @include mq(tbl) {
            @include fs(16,24);
          }
        }
        .ja {
          @include jaBold;
          @include fs(20,20);
          display: block;
          letter-spacing: 1px;
          margin-top: .3em;
          @include mq(tb) {
            @include fs(30,30);
            letter-spacing: 2px;
          }
          @include mq(tbl) {
            @include fs(36,48);
          }
        }
      }
      .lead {
        margin-top: 30px;
        @include mq(tb) {
          margin-top: 50px;
        }
        @include mq(tbl) {
          margin-top: 40px;
        }
        .ja {
          @include fs(13,28);
          padding-bottom: 1.2em;
          @include mq(tb) {
            @include fs(14,30);
          }
          @include mq(tbl) {
            @include fs(15,36);
          }
        }
        .en {
          @include en;
          @include fs(12,28);
          padding-bottom: 1.2em;
          @include mq(tb) {
            @include fs(13,30);
          }
        }
      }
      .s_button {
        margin-top: 20px;
        @include mq(tb) {
          margin-top: 40px;
        }
        @include mq(tbl) {
          margin-top: 50px;
          margin-right: 0;
        }
      }
    }
  }
  &.jobseeker {
    .inner {
      .image {
        background-image: url(#{$imagePath}jobseeker_mv.jpg);
      }
      .text {
        @include mq(tbl) {
          padding-bottom: 130px;
        }
      }
    }
  }
  &.company {
    @include mq(tbl) {
      margin-top: -30px;
    }
    .inner {
      @include mq(tbl) {
        @include flex-direction(row-reverse);
      }
      .image {
        background-image: url(#{$imagePath}company_mv.jpg);
      }
      .text {
        @include mq(tbl) {
          padding-left: 0;
          padding-right: 50px;
          text-align: left;
          padding-top: 170px;
          .s_button {
            margin-left: 0;
            margin-right: auto;
          }
        }
        @include mq(pc) {
          padding-right: 0;
          padding-left: 75px;
        }
      }
    }
  }
}
.m_top_news {
  margin-top: 50px;
  padding: 0 $ps;
  background-color: $main;
  @include mq(tb) {
    margin-top: 70px;
  }
  @include mq(tbl) {
    margin-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .inner {
    padding: 30px 0;
    @include mq(tb) {
      padding: 40px 0;
    }
    @include mq(tbl) {
      padding: 60px 0 65px;
    }
    @include mq(pc) {
      @include container;
    }
    h2 {
      color: white;
      text-align: center;
      .en {
        @include enBold;
        @include fs(20,20);
        display: block;
        letter-spacing: 2px;
        text-transform: uppercase;
        @include mq(tb) {
          @include fs(25,25);
          letter-spacing: 3px;
        }
        @include mq(tbl) {
          @include fs(32,32);
          letter-spacing: 5px;
          text-align: left;
        }
      }
      .ja {
        margin-top: .4em;
        @include ja;
        @include fs(11,11);
        display: block;
        @include mq(tb) {
          @include fs(13,13);
          letter-spacing: 1px;
        }
        @include mq(tbl) {
          @include fs(16,16);
          text-align: left;
        }
      }
    }
    ul {
      @include mq(tb) {
        margin-top: 40px;
      }
      @include mq(tbl) {
        margin-top: 60px;
        @include flexbox;
        @include flex-wrap(wrap);
      }
      li {
        margin-top: 20px;
        @include mq(tbl) {
          margin-top: 0;
          width: 31%;
          @include flexbox;
          margin-right: 3%;
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
        @include mq(pc) {
          width: 350px;
          margin-right: 25px;
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
        a {
          display: block;
          background-color: white;
          transition: box-shadow .2s ease;
          @include mq(tbl) {
            @include flex(1);
            &:hover {
              box-shadow: inset 0px 0px 0px 3px $black;
            }
          }
          .date {
            display: inline-block;
            background-color: $black;
            color: white;
            @include fs(10,10);
            letter-spacing: 2px;
            padding: 4px 10px;
            font-style: italic;
            @include mq(tb) {
              @include fs(11,11);
              padding: 6px 12px;
            }
            @include mq(tbl) {
              @include fs(13,13);
              padding: 8px 18px;
            }
          }
          h3 {
            padding: 13px 15px 15px;
            @include fs(13,21);
            @include mq(tb) {
              padding: 15px 18px 17px;
              @include fs(14,24);
            }
            @include mq(tbl) {
              padding: 25px 20px 55px;
              @include fs(15,25);
            }
          }
        }
      }
    }
  }
}
