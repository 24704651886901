/* compiled from /scss/_modules.scss */

@charset "UTF-8";





@import "variables";
@import "mixins";
@import "flexbox";



.m_newslist {
  padding: 0 $ps 40px;
  text-align: left;
  @include mq(tbl) {
    padding: 0 50px 60px;
  }
  @include mq(pc) {
    padding: 0 0 80px;
    @include container;
  }
  ul {
    @include mq(tbl) {
      @include flexbox;
      @include justify-content(space-between);
      @include flex-wrap(wrap);
    }
    li {
      margin-top: 20px;
      @include mq(tbl) {
        margin-top: 0;
        width: 49%;
        @include flexbox;
        margin-bottom: 2vw;
      }
      @include mq(pc) {
        margin-bottom: 20px;
      }
      a {
        display: block;
        border: 1px solid $black;
        transition: box-shadow .2s ease;
        @include mq(tbl) {
          @include flex(1);
          &:hover {
            box-shadow: inset 0px 0px 0px 3px $black;
          }
        }
        .date {
          display: inline-block;
          background-color: $black;
          color: white;
          @include fs(10,10);
          letter-spacing: 2px;
          padding: 4px 10px;
          font-style: italic;
          @include mq(tb) {
            @include fs(11,11);
            padding: 6px 12px;
          }
          @include mq(tbl) {
            @include fs(13,13);
            padding: 8px 18px;
          }
        }
        h3 {
          padding: 13px 15px 15px;
          @include fs(13,21);
          @include mq(tb) {
            padding: 15px 18px 17px;
            @include fs(14,24);
          }
          @include mq(tbl) {
            padding: 25px 20px 45px;
            @include fs(15,25);
          }
        }
      }
    }
  }
}
.m_pagenav {
  padding: 0 0 80px;
  position: relative;
  margin: 0 $ps;
  @include mq(tbl) {
    margin: 0 50px;
    padding-bottom: 140px;
  }
  @include mq(pc) {
    padding: 0 0 180px;
    @include container;
  }
  a {
    display: block;
    @include fs(12,12);
    line-height: 1;
    position: absolute;
    left: 0;
    background-color: $main;
    padding: 10px 12px 18px;
    color: white;
    @include mq(tbl) {
      padding: 14px 66px 18px;
    }
    &:before {
      content: '';
      background-color: $black;
      display: block;
      position: absolute;
      width: 0;
      height: 100%;
      left: 0;
      bottom: 0;
      transition: width .4s ease-in-out;
      z-index: 1;
    }
    &:hover {
      @include mq(tbl) {
        &:before {
          width: 100%;
        }
      }
    }
    &.next {
      left: auto;
      right: 0;
      &:after {
        content: '';
        width: 17px;
        height: 4.5px;
        background-image: url(/images/next.png);
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        position: relative;
        z-index: 2;
        @include mq(tbl) {
          width: 34px;
          height: 9px;
          background-image: url(/images/next_pc.png);
          display: inline-block;
        }
      }
    }
    &.prev {
      &:after {
        content: '';
        width: 17px;
        height: 4.5px;
        background-image: url(/images/prev.png);
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        position: relative;
        z-index: 2;
        @include mq(tbl) {
          width: 34px;
          height: 9px;
          background-image: url(/images/prev_pc.png);
          display: inline-block;
        }
      }
    }
  }
}
.m_newsdetail {
  padding: 0 $ps 40px;
  text-align: left;
  @include mq(tbl) {
    padding: 0 50px 80px;
  }
  @include mq(pc) {
    padding: 0 0 80px;
    @include container;
  }
  .title {
    padding: 30px $ps;
    text-align: center;
    @include mq(tb) {
      padding: 40px $ps;
    }
    @include mq(tbl) {
      padding: 60px $ps;
    }
    @include mq(pc) {
      padding: 80px 0;
    }
    .date {
      @include enBold;
      @include fs(12,18);
      display: block;
      letter-spacing: 1px;
      color: $main;
      @include mq(tb) {
        @include fs(14,22);
      }
      @include mq(tbl) {
        @include fs(18,28);
      }
      @include mq(pc) {
        @include fs(24,36);
      }
    }
    h1 {
      @include jaBold;
      @include fs(26,36);
      display: block;
      letter-spacing: 1px;
      margin-top: 4px;
      @include mq(tb) {
        @include fs(36,56);
        margin-top: 0;
      }
      @include mq(tbl) {
        @include fs(40,60);
        letter-spacing: 4px;
        margin-top: 4px;
      }
    }
  }
  .contents {
    @include mq(tbl) {
      padding: 0 80px;
    }
    @include mq(pc) {
      padding: 0 150px;
    }
    p {
      @include fs(14,28);
      padding-bottom: 1.5em;
      @include mq(tbl) {
        @include fs(16,32);
      }
      img {
        margin: 5px 0 10px;
        margin: 0 auto;
        width: auto;
        max-width: 100%;
      }
    }
    .movie {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
