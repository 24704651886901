/* compiled from /scss/_modules.scss */

@charset "UTF-8";





@import "variables";
@import "mixins";
@import "flexbox";



.s_button {
  background-color: $black;
  display: block;
  padding: 20px;
  color: $white;
  position: relative;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  @include mq(tb) {
    padding: 22px 20px 25px;
  }
  @include mq(tbl) {
    padding: 26px 20px 30px;
  }
  &:before {
    content: '';
    background-color: $main;
    display: block;
    position: absolute;
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    transition: width .4s ease-in-out;
  }
  &:hover {
    @include mq(tbl) {
      &:before {
        width: 100%;
      }
    }
  }
  strong, span {
    display: block;
    text-align: center;
    position: relative;
    z-index: 2;
    letter-spacing: 1px;
  }
  strong {
    @include jaBold;
    @include fs(16,16);
    @include mq(tb) {
      @include fs(18,18);
    }
    @include mq(tbl) {
      @include fs(22,22);
    }
  }
  span {
    @include en;
    @include fs(9,9);
    margin-top: 8px;
    @include mq(tb) {
      @include fs(11,11);
    }
    @include mq(tbl) {
      @include fs(12,12);
    }
  }
}
.m_mv {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 42vw;
  max-width: 1600px;
  margin: 0 auto;
  @include mq(pc) {
    height: 590px;
  }
  &.top {
    height: 56vw;
    background-image: url(#{$imagePath}top_mv.jpg);
    @include mq(pc) {
      height: 56vw;
    }
  }
  &.sanga {
    height: 56vw;
    background-image: url(#{$imagePath}sanga_a.gif);
    @include mq(pc) {
      height: 56vw;
    }
  }
  &.jobseeker {
    background-image: url(#{$imagePath}jobseeker_mv.jpg);
  }
  &.company {
    background-image: url(#{$imagePath}company_mv.jpg);
  }
  &.team {
    background-image: url(#{$imagePath}team_mv.jpg);
  }
  &.about {
    background-image: url(#{$imagePath}about_mv.jpg);
  }
}
.m_title {
  padding: 30px $ps;
  @include mq(tb) {
    padding: 40px $ps;
  }
  @include mq(tbl) {
    padding: 60px $ps;
  }
  @include mq(pc) {
    padding: 80px 0;
  }
  &.pc_left {
    @include mq(tbl) {
      @include container;
    }
    h1 {
      @include mq(tbl) {
        text-align: left;
      }
    }
  }
  h1 {
    text-align: center;
    .en {
      @include enBold;
      @include fs(12,18);
      display: block;
      letter-spacing: 1px;
      color: $main;
      @include mq(tb) {
        @include fs(14,22);
      }
      @include mq(tbl) {
        @include fs(18,28);
      }
      @include mq(pc) {
        @include fs(24,36);
      }
    }
    .ja {
      @include jaBold;
      @include fs(26,36);
      display: block;
      letter-spacing: 2px;
      margin-top: 4px;
      @include mq(tb) {
        @include fs(36,56);
        letter-spacing: 2px;
        margin-top: 0;
      }
      @include mq(tbl) {
        @include fs(45,65);
        letter-spacing: 8px;
        margin-top: 4px;
      }
      @include mq(pc) {
        @include fs(50,75);
      }
    }
  }
  .lead {
    text-align: center;
    margin-top: 30px;
    @include mq(tbl) {
      margin-top: 50px;
    }
    @include mq(pc) {
      margin-top: 60px;
    }
    .ja {
      @include fs(15,30);
      padding-bottom: 1em;
      @include mq(tb) {
        @include fs(16,32);
      }
      @include mq(tbl) {
        @include fs(17,34);
      }
      @include mq(pc) {
        @include fs(18,36);
      }
    }
    .en {
      @include en;
      @include fs(14,28);
      @include mq(tb) {
        @include fs(15,30);
      }
      @include mq(tbl) {
        @include fs(16,32);
      }
      @include mq(pc) {
      }
    }
  }
}
.m_subtitle {
  padding: 10px $ps 30px;
  @include mq(tb) {
    padding: 20px $ps 40px;
  }
  @include mq(tbl) {
    padding: 40px $ps 60px;
  }
  @include mq(pc) {
    padding: 40px 0 60px;
  }
  h2 {
    text-align: center;
    .en {
      @include enBold;
      @include fs(10,16);
      display: block;
      letter-spacing: 1px;
      color: $main;
      @include mq(tb) {
        @include fs(12,21);
      }
      @include mq(tbl) {
        @include fs(16,26);
      }
      @include mq(pc) {
        @include fs(16,26);
      }
    }
    .ja {
      @include jaBold;
      @include fs(24,36);
      display: block;
      letter-spacing: 2px;
      margin-top: 0px;
      @include mq(tb) {
        @include fs(28,40);
        letter-spacing: 2px;
      }
      @include mq(tbl) {
        @include fs(30,50);
        letter-spacing: 8px;
      }
      @include mq(pc) {
        @include fs(36,60);
      }
    }
  }
}

.m_subtitle_b {
  margin-top: 40px;
  padding: 0 $ps;
  @include mq(tbl) {
    @include container;
    margin-top: 100px;
    padding: 0;
  }
  h2 {
    border-left: 2px solid $main;
    padding: 0 0 3px 10px;
    @include mq(tbl) {
      border-left-width: 4px;
      padding: 10px 0 14px 26px;
    }
    .ja {
      @include jaBold;
      @include fs(20,26);
      display: block;
      margin-top: 0px;
      @include mq(tbl) {
        @include fs(32,44);
        letter-spacing: .1em;
      }
    }
    .en {
      margin-left: 2px;
      @include en;
      @include fs(9,12);
      display: block;
      letter-spacing: .1em;
      color: $main;
      @include mq(tbl) {
        @include fs(14,18);
        @include enBold;
      }
    }
  }
}
.m_breadcrumbs {
  padding: 8px 0;
  background-color: #e8e8e8;
  @include mq(tb) {
    padding: 12px 0;
  }
  @include mq(tbl) {
    padding: 25px 0;
  }
  .inner {
    padding: 10px $ps;
    @include mq(pc) {
      @include container;
    }
    ul {
      @include flexbox;
      @include flex-wrap(wrap);
      li {
        @include fs(11,18);
        @include mq(tbl) {
          @include fs(13,21);
        }
        a {
          position: relative;
          display: block;
          padding-right: 18px;
          margin-right: 12px;
          transition: color .2s ease;
          @include mq(tbl) {
            padding-right: 22px;
            margin-right: 16px;
            &:hover {
              color: $main;
            }
          }
          &:after, &:before {
            content: '';
            background-color: #aaa;
            position: absolute;
            right: 0;
            top: 6px;
            width: 6px;
            height: 1px;
            transform: rotateZ(45deg);
          }
          &:before {
            transform: rotateZ(-45deg);
            top: 10px;
          }
        }
      }
    }
  }
}
.m_contact {
  padding: 40px $ps;
  text-align: center;
  @include mq(tb) {
    padding: 50px $ps;
  }
  @include mq(tbl) {
    padding: 85px 0;
  }
  .inner {
    @include mq(tbl) {
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);
    }
    @include mq(pc) {
      @include container;
    }
    h2 {
      @include mq(tbl) {
        padding-right: 70px;
      }
      .ja {
        @include jaBold;
        @include fs(16,26);
        display: block;
        @include mq(tb) {
          @include fs(20,30);
        }
        @include mq(tbl) {
          @include fs(24,36);
        }
      }
      .en {
        margin-top: .2em;
        @include en;
        @include fs(12,12);
        letter-spacing: 1px;
        display: block;
        @include mq(tb) {
          @include fs(13,13);
        }
        @include mq(tbl) {
          @include fs(16,16);
          margin-top: .4em;
        }
      }
    }
    .way {
      margin-top: 20px;
      @include mq(tb) {
        margin-top: 30px;
      }
      @include mq(tbl) {
        margin-top: 0;
        padding-left: 70px;
      }
      .tel {
        @include enBold;
        letter-spacing: 1px;
        @include fs(20,20);
        @include mq(tb) {
          @include fs(22,22);
        }
        @include mq(tbl) {
          @include fs(24,24);
        }
        span {
          @include fs(24,24);
          @include mq(tb) {
            @include fs(25,25);
          }
          @include mq(tbl) {
            @include fs(30,30);
          }
        }
      }
      .button {
        margin-top: 25px;
        border: 2px solid $black;
        display: block;
        padding: 16px;
        position: relative;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        @include mq(tb) {
          margin-top: 30px;
          padding: 22px 20px 25px;
        }
        @include mq(tbl) {
          padding: 20px 55px 25px;
        }
        &:before {
          content: '';
          background-color: $black;
          display: block;
          position: absolute;
          width: 0;
          height: 100%;
          left: 0;
          top: 0;
          transition: width .4s ease-in-out, color .4s ease-in-out;
        }
        &:hover {
          @include mq(tbl) {
            color: $white;
            &:before {
              width: 100%;
            }
          }
        }
        strong, span {
          display: block;
          text-align: center;
          position: relative;
          z-index: 2;
          letter-spacing: 1px;
        }
        strong {
          @include jaBold;
          @include fs(16,16);
          @include mq(tb) {
            @include fs(18,18);
          }
          @include mq(tbl) {
            @include fs(20,20);
          }
        }
        span {
          @include en;
          @include fs(9,9);
          margin-top: 8px;
          @include mq(tb) {
            @include fs(11,11);
          }
          @include mq(tbl) {
            @include fs(12,12);
          }
        }
      }
    }
  }
}
div.wpcf7-response-output {
  margin: 0 0 30px !important;
  text-align: center;
  border: none;
  @include fs(13,21);
  @media (min-width: $screen-sm-min) {
    margin: 30px 0 0 !important;
    @include fs(14,24);
  }
  &.wpcf7-acceptance-missing {
    background: none;
    border: none;
    color: $red;
  }
  &.wpcf7-validation-errors {
    background: none;
    border: none;
    color: $red;
  }
  &.wpcf7-mail-sent-ok {
    background: none;
    border: none;
    color: white;
  }
}
.wpcf7-form {
  &.sent {
    .form_btns {
      display: none;
    }
  }
}
span.wpcf7-not-valid-tip {
  margin-top: 10px;
}
.m_sanga {
  background-color: #750069;
  .inner {
    padding: 40px $ps;
    color: white;
    text-align: center;
    @include mq(tbl) {
      padding: 50px;
      @include flexbox;
      @include flex-direction(row-reverse);
      @include align-items(center);
    }
    @include mq(pc) {
      @include container;
      padding: 50px 0;
    }
    .text {
      @include mq(tbl) {
        padding-left: 40px;
        width: 50%;
      }
      @include mq(pc) {
        width: 62%;
      }
      h2 {
        .ja {
          @include jaBold;
          @include fs(18,28);
          display: block;
          @include mq(tb) {
            @include fs(20,30);
          }
          @include mq(tbl) {
            @include fs(30,45);
            letter-spacing: 2px;
          }
        }
        .en {
          margin-top: .2em;
          @include enBold;
          @include fs(12,12);
          letter-spacing: 1px;
          opacity: .4;
          font-style: italic;
          display: block;
          @include mq(tb) {
            @include fs(13,13);
          }
          @include mq(tbl) {
            @include fs(16,16);
            margin-top: .4em;
            letter-spacing: 2px;
          }
        }
      }
      .lead {
        margin-top: 2em;
        @include fs(13,24);
        @include mq(tbl) {
          @include fs(14,28);
          letter-spacing: 1px;
        }
      }
    }
    .buttons {
      @include mq(tbl) {
        width: 50%;
        padding: 20px 40px 15px 0;
        border-right: 1px solid #9b4793;
      }
      @include mq(pc) {
        width: 38%;
        padding-right: 70px;
      }
      ul {
        width: 70%;
        margin: 30px auto 0;
        max-width: 350px;
        @include mq(tbl) {
          margin-top: 0;
          width: 100%;
        }
        li {
          img {
            width: 130px;
            border: 1px solid white;
            @include mq(tbl) {
              width: 180px;
            }
          }
        }
      }
      .button {
        margin-top: 25px;
        border: 2px solid white;
        display: block;
        padding: 16px;
        position: relative;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        color: white;
        @include mq(tb) {
          margin-top: 30px;
          padding: 22px 20px 25px;
        }
        @include mq(tbl) {
          padding: 15px 55px 25px;
          margin: 25px auto 0;
        }
        &:before {
          content: '';
          background-color: #af6ba8;
          display: block;
          position: absolute;
          width: 0;
          height: 100%;
          left: 0;
          top: 0;
          transition: width .4s ease-in-out, color .4s ease-in-out;
        }
        &:hover {
          @include mq(tbl) {
            color: $white;
            &:before {
              width: 100%;
            }
          }
        }
        strong, span {
          display: block;
          text-align: center;
          position: relative;
          z-index: 2;
          letter-spacing: 1px;
        }
        strong {
          @include jaBold;
          @include fs(16,16);
          @include mq(tb) {
            @include fs(18,18);
          }
          @include mq(tbl) {
            @include fs(20,30);
          }
        }
        span {
          @include en;
          @include fs(9,9);
          margin-top: 8px;
          @include mq(tb) {
            @include fs(11,11);
          }
          @include mq(tbl) {
            @include fs(12,12);
            margin-top: 4px;
          }
        }
      }
    }
  }
}
.m_sanga_mv {
  background-color: #750069;
  .inner {
    padding: 60px $ps;
    color: white;
    text-align: center;
    @include mq(tbl) {
      padding: 90px 50px;
    }
    @include mq(pc) {
      @include container;
      padding: 90px 0;
    }
    h1 {
      .ja {
        @include jaBold;
        display: block;
        @include fs(26,38);
        letter-spacing: 2px;
        @include mq(tb) {
          @include fs(32,46);
        }
        @include mq(tbl) {
          @include fs(42,60);
        }
      }
      .en {
        margin-top: .75em;
        @include enBold;
        @include fs(12,12);
        letter-spacing: 2px;
        opacity: .4;
        font-style: italic;
        display: block;
        @include mq(tb) {
          @include fs(13,13);
        }
        @include mq(tbl) {
          @include fs(16,16);
        }
      }
    }
    ul {
      width: 70%;
      margin: 40px auto 0;
      max-width: 350px;
      @include mq(tbl) {
        margin-top: 70px;
        width: 100%;
        max-width: 520px;
      }
      li {
        img {
          max-width: 180px;
          border: 1px solid white;
          @include mq(tbl) {
            max-width: 250px;
          }
        }
      }
    }
    .lead {
      margin-top: 40px;
      @include fs(13,26);
      @include mq(tbl) {
        @include fs(14,28);
        margin-top: 65px;
        letter-spacing: 1px;
      }
    }
  }
}
.m_sanga_photo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 38vw;
  //max-width: 1600px;
  margin: 0 auto;
  background-image: url(#{$imagePath}_H1A9956.jpg);
  @include mq(pc) {
    height: 450px;
    background-position: center top;
  }
}
.m_sanga_movie {
  .inner {
    padding: 60px $ps 80px;
    color: white;
    text-align: center;
    @include mq(tbl) {
      padding: 110px 50px 150px;
    }
    @include mq(pc) {
      @include container;
      padding: 110px 0 150px;
    }
    h2 {
      color: #750069;
      @include enBold;
      font-style: italic;
      @include fs(22,22);
      letter-spacing: 2px;
      @include mq(tbl) {
        @include fs(36,36);
      }
    }
    .lead {
      margin-top: 30px;
      color: #750069;
      @include fs(13,26);
      @include mq(tbl) {
        margin-top: 60px;
        @include fs(16,32);
      }
      a {
        color: #750069;
        text-decoration: underline;
        &:hover {
          @include mq(tbl) {
            text-decoration: none;
          }
        }
      }
    }
    ul {
      @include mq(tbl) {
        margin-top: 80px;
        @include flexbox;
        @include justify-content(space-between);
      }
      li {
        max-width: 350px;
        margin: 40px auto 0;
        @include mq(tbl) {
          max-width: none;
          margin: 0;
          &:first-child {
            margin-right: 20px;
          }
          &:last-child {
            margin-left: 20px;
          }
        }
        @include mq(pc) {
          &:first-child {
            margin-right: 50px;
          }
          &:last-child {
            margin-left: 50px;
          }
        }
        .thumb {
          a {
            display: block;
            border: 2px solid #750069;
          }
        }
        .button {
          margin-top: 15px;
          border: 2px solid #750069;
          background-color: #750069;
          display: block;
          padding: 16px;
          position: relative;
          margin-left: auto;
          margin-right: auto;
          @include mq(tbl) {
            padding: 20px;
            margin-top: 40px;
          }
          &:before {
            content: '';
            background-color: #af6ba8;
            display: block;
            position: absolute;
            width: 0;
            height: 100%;
            left: 0;
            top: 0;
            transition: width .4s ease-in-out, color .4s ease-in-out;
          }
          &:hover {
            @include mq(tbl) {
              &:before {
                width: 100%;
              }
            }
          }
          strong {
            color: white;
            @include en;
            text-transform: uppercase;
            letter-spacing: 2px;
            @include fs(16,16);
            position: relative;
            z-index: 1;
            @include mq(tbl) {
              @include fs(20,20);
            }
          }
        }
      }
    }
  }
}
.m_sanga_credit {
  background-color: #750069;
  .inner {
    padding: 60px $ps;
    color: white;
    text-align: center;
    @include mq(tbl) {
      padding: 70px 50px;
    }
    @include mq(pc) {
      @include container;
    }
    dl {
      letter-spacing: 1px;
      dt {
        margin-top: 30px;
        @include fs(12,22);
        @include mq(tbl) {
          @include fs(14,24);
        }
        &:first-child {
          margin-top: 0;
        }
      }
      dd {
        @include fs(16,26);
        margin-top: 5px;
        @include jaBold;
        @include mq(tbl) {
          @include fs(18,28);
        }
        span {
          @include fs(12,22);
          @include ja;
          @include mq(tbl) {
            @include fs(14,24);
          }
        }
      }
    }
  }
}

.m_business_outline {
  margin-top: 40px;
  padding: 0 $ps;
  @include mq(tbl) {
    margin-top: 60px;
  }
  .inner {
    @include mq(tbl) {
      @include container;
      display: flex;
    }
    .unit {
      border: 3px solid #E5E5E5;
      background-color: white;
      position: relative;
      z-index: 2;
      padding: 20px 20px 30px;
      border-radius: 15px;
      margin-top: -15px;
      filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.1));
      @include mq(tbl) {
        border-width: 10px;
        border-radius: 50%;
        width: 334px;
        height: 334px;
        margin-left: -80px;
        margin-top: 0;
        filter: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 30px 20px 80px;
        z-index: 4;
      }
      h3 {
        color: #E45571;
        @include jaBold;
        @include fs(15,15);
        @include mq(tbl) {
          @include fs(22,22);
        }
        strong {
          @include fs(18,18);
          @include mq(tbl) {
            @include fs(25,25);
          }
        }
      }
      ul {
        margin-top: 15px;
        li {
          @include fs(14,24);
          padding-left: 1em;
          position: relative;
          &:before {
            content: '\30FB';
            position: absolute;
            left: 0;
          }
        }
      }
      &:nth-child(1){
        @include mq(tbl) {
          margin-left: 0;
          padding-left: 40px;
        }
      }
      &:nth-child(2){
        @include mq(tbl) {
          z-index: 3;
        }
        h3 {
          color: #68AE24;
        }
      }
      &:nth-child(3){
        @include mq(tbl) {
          z-index: 2;
        }
        h3 {
          color: #E6B342;
        }
      }
      &:nth-child(4){
        padding-bottom: 20px;
        @include mq(tbl) {
          z-index: 1;
        }
        h3 {
          color: #1D94DD;
        }
      }
    }
  }
}
.m_business_examples {
  padding: 0 $ps;
  @include mq(tbl) {
    padding: 0;
    margin-top: 60px;
  }
  .inner {
    @include mq(tbl) {
      @include container;
    }
    .unit {
      margin-top: 30px;
      @include mq(tbl) {
        margin-top: 40px;
        display: flex;
        gap: 50px;
      }
      h3 {
        background-color: $main;
        color: white;
        @include jaBold;
        @include fs(15,25);
        padding: 12px;
        @include mq(tbl) {
          @include fs(20,35);
          padding: 20px 40px 20px;
          width: 350px;
          display: flex;
          align-items: center;
        }
        br {
          display: none;
          @include mq(tbl) {
            display: inline;
          }
        }
      }
      .type {
        margin-top: 15px;
        @include mq(tbl) {
          margin-top: 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        h4 {
          @include jaBold;
          @include fs(15,25);
          @include mq(tbl) {
            @include fs(20,35);
          }
          span {
            @include fs(12,22);
            @include mq(tbl) {
              @include fs(16,26);
            }
          }
        }
        p {
          margin-top: 15px;
          @include fs(13,21);
          @include mq(tbl) {
            @include fs(16,26);
          }
        }
      }
    }
    .note {
      text-align: right;
      margin-top: 15px;
      @include fs(10,18);
      @include mq(tbl) {
        margin-top: 20px;
        @include fs(12,18);
      }
    }
  }
}
.m_other_business {
  padding: 0 $ps;
  margin-top: 30px;
  @include mq(tbl) {
    padding: 0;
    margin-top: 50px;
  }
  .inner {
    @include mq(tbl) {
      @include container;
      display: flex;
      gap: 50px;
    }
    .unit {
      margin-top: 20px;
      border: 4px solid #E5E5E5;
      background-color: white;
      padding: 20px 20px 30px;
      @include mq(tbl) {
        flex: 1;
        margin-top: 0;
        padding: 45px 35px;
      }
      h3 {
        @include jaBold;
        @include fs(16,25);
        color: $main;
        @include mq(tbl) {
          @include fs(25,35);
        }
      }
      ul {
        margin-top: 15px;
        @include mq(tbl) {
          margin-top: 30px;
        }
        li {
          @include fs(14,24);
          padding-left: 1em;
          position: relative;
          margin-top: 1em;
          @include mq(tbl) {
            @include fs(16,28);
          }
          &:first-child {
            margin-top: 0;
          }
          &:before {
            content: '\30FB';
            position: absolute;
            left: 0;
          }
          strong {
            @include jaBold;
          }
        }
      }
      .button {
        text-align: right;
        margin-top: 15px;
        a {
          background-color: #ED8927;
          display: inline-block;
          padding: 14px 20px 14px 30px;
          color: $white;
          position: relative;
          border-radius: 30px;
          @include mq(tbl) {
            padding: 16px 22px 16px 45px;
            @include fs(16,16);
          }
          &:hover {
            @include mq(tbl) {
              i {
                transform: translateX(4px);
              }
            }
          }
          i {
            display: inline-block;
            margin-left: 10px;
            transition: transform .3s ease;
            @include mq(tbl) {
              margin-left: 22px;
            }
            img {
              width: 7px;
              transform: translateY(-1px);
              @include mq(tbl) {
                width: 8px;
                transform: translateY(-1px);
              }
            }
          }
        }
      }
      .note {
        margin-top: 20px;
        @include fs(12,19);
        @include mq(tbl) {
          margin-top: 30px;
          @include fs(14,24);
        }
      }
    }
  }
}
.m_other_business_outro {
  padding: 0 $ps;
  margin-top: 30px;
  @include mq(tbl) {
    padding: 0;
    margin-top: 50px;
  }
  .inner {
    @include mq(tbl) {
      @include container;
    }
    p {
      text-align: center;
      @include jaBold;
      @include fs(16,32);
      @include mq(tbl) {
        @include fs(24,40);
      }
    }
  }
}

.m_contact {
  margin-top: 20px;
  padding-bottom: 80px;
  @include mq(tbl) {
    margin-top: 50px;
    padding-bottom: 200px;
  }
  .athlete {
    @include jaBold;
    @include fs(14,28);
    @include mq(tbl) {
      @include fs(18,32);
    }
  }
}
.m_top_doctor {
  margin-top: 30px;
  @include mq(tbl) {
    margin-top: 50px;
  }
  .inner {
    padding: 0 $ps 30px;
    @include mq(tbl) {
      display: flex;
      gap: 30px;
      justify-content: center;
    }
    @include mq(pc) {
      @include container;
      gap: 40px;
    }
    .s_button_doctor {
      margin-top: 20px;
      background-color: #e7b900;
      @include mq(tbl) {
        margin: 0;
        width: 400px;
      }
      @include mq(pc) {
        width: 460px;
      }
      &.athlete {
        background-color: #ed8927;

      }
    }
  }
}
.m_team {
  .unit {
    .photo {
      $members: mouri_takafumi, mouri_nobuyuki, mukai_tatsuro, daisuke_yamamoto, shota_takemoto, maho_hatano, ayako_mizuno;
      @each $member in $members {
        &.#{$member} {
          background-image: url(#{$imagePath}#{$member}.jpg);
        }
      }
    }
  }
}
.m_privacy {
  padding: 0 $ps 80px;
  text-align: left;
  @include mq(tbl) {
    padding: 0 50px 160px;
  }
  @include mq(pc) {
    padding: 0 0 160px;
    @include container;
  }
  .title {
    padding: 30px $ps;
    text-align: center;
    @include mq(tb) {
      padding: 40px $ps 70px;
    }
    @include mq(tbl) {
      padding: 60px $ps 100px;
    }
    @include mq(pc) {
      padding: 80px 0 140px;
    }
    .date {
      @include enBold;
      @include fs(12,18);
      display: block;
      letter-spacing: 1px;
      color: $main;
      @include mq(tb) {
        @include fs(14,22);
      }
      @include mq(tbl) {
        @include fs(18,28);
      }
      @include mq(pc) {
        @include fs(24,36);
      }
    }
    h1 {
      @include jaBold;
      @include fs(26,36);
      display: block;
      letter-spacing: 1px;
      margin-top: 4px;
      @include mq(tb) {
        @include fs(36,56);
        margin-top: 0;
      }
      @include mq(tbl) {
        @include fs(40,60);
        letter-spacing: 2px;
        margin-top: 4px;
      }
    }
  }
  .contents {
    @include mq(tbl) {
      padding: 0 80px;
    }
    @include mq(pc) {
      padding: 0 150px;
    }
    p, ul, ol, h2, h3 {
      @include fs(14,28);
      padding-bottom: 1.5em;
      @include mq(tbl) {
        @include fs(16,32);
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
      &.nopadding {
        padding-bottom: 0;
      }
    }
    h2 {
      color: $main;
      display: inline-block;
      font-weight: 600;
      border-bottom: 1px solid $main;
      padding-bottom: 3px;
      margin-bottom: 1.5em;
      margin-top: 1em;
    }
    h3 {
      color: $main;
      font-weight: 600;
    }
    ol {
      counter-reset: section;
      list-style: none;
      li {
        position: relative;
        padding-left: 25px;
        &::before {
          position: absolute;
          left: 0;
          counter-increment: section;
          content: "("counter(section)")";
        }
        ol {
          padding-bottom: 0;
          li {
            &::before {
              counter-increment: section;
              content: ""counter(section)".";
            }
            
          }
        }
      }
    }
    ul {
      li {
        position: relative;
        padding-left: 25px;
        &::before {
          content: '・';
          position: absolute;
          left: 0;
        }
      }
    }
    .claim {
      margin: 15px 0 25px;
      border: 2px solid $main;
      padding: $ps $ps 0;
      @include mq(tbl) {
        padding: 30px 30px 0;
      }
    }
  }
}