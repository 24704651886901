/* imported from /scss/_utilities.scss */

@for $i from 0 through 20{
	.mT#{$i * 5}{
		margin-top: 5px * $i !important;
	}
	.mR#{$i * 5}{
		margin-right: 5px * $i !important;
	}
	.mB#{$i * 5}{
		margin-bottom: 5px * $i !important;
	}
	.mL#{$i * 5}{
		margin-left: 5px * $i !important;
	}
	.pT#{$i * 5}{
		padding-top: 5px * $i !important;
	}
	.pR#{$i * 5}{
		padding-right: 5px * $i !important;
	}
	.pB#{$i * 5}{
		padding-bottom: 5px * $i !important;
	}
	.pL#{$i * 5}{
		padding-left: 5px * $i !important;
	}
}
.w5 {
  width: 5%;
}
.w10 {
  width: 10%;
}
.w15 {
  width: 15%;
}
.w20 {
  width: 20%;
}
.w23 {
  width: 23%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30%;
}
.w33 {
  width: 33%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w66 {
  width: 66%;
}
.w70 {
  width: 70%;
}
.w75 {
  width: 75%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w95 {
  width: 95%;
}

.vT {
  vertical-align: top !important;
}
.vM {
  vertical-align: middle !important;
}
.vB {
  vertical-align: bottom !important;
}
.txtC {
  text-align: center !important;
}
.txtL {
  text-align: left !important;
}
.txtR {
  text-align: right !important;
}
.nowrap { white-space: nowrap; }

.uppercase { text-transform: uppercase; }

.txtB { font-weight: bold; }
