$screen-xs-min: 400px;
$screen-sm-min: 768px;
$container: 1100px;

$main: #14b4aa;
$black: #202020;
$white: #F5F3F0;

$beige: #D8CFC4;
$red: #C93F1C;

$pt: 55px;
$ps: 20px;

$imagePath: '/images/';

$breakpoints: (
  'sp': 'screen and (min-width: 560px)',
  'tb': 'screen and (min-width: 768px)',
  'tbl': 'screen and (min-width: 960px)',
  'pc': 'screen and (min-width: 1260px)',
  'pcl': 'screen and (min-width: 1300px)',
) !default;
