/* compiled from /scss/_modules.scss */

@charset "UTF-8";





@import "variables";
@import "mixins";
@import "flexbox";



.m_about {
  padding: 0 $ps;
  margin-top: 30px;
  @include mq(tbl) {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 60px;
  }
  @include mq(pc) {
    padding: 0;
    width: 1020px;
    margin-left: auto;
    margin-right: auto;
  }
  .unit {
    margin-bottom: 25px;
    @include mq(tb) {
      margin-bottom: 30px;
      @include flexbox;
    }
    .ja {
      @include mq(tb) {
        @include flex(1);
        padding-right: 15px;
      }
      @include mq(tbl) {
        padding-right: 25px;
      }
      @include mq(pc) {
        padding-right: 50px;
      }
      h2 {
        border-bottom: 2px solid $main;
        @include jaBold;
        padding: 0 0 4px;
        @include fs(14,21);
        margin-bottom: 15px;
        @include mq(tb) {
          @include fs(15,21);
        }
        @include mq(tbl) {
          @include fs(16,21);
          padding: 0 0 6px;
          margin-bottom: 18px;
        }
        @include mq(pc) {
          @include fs(17,21);
          padding: 0 0 8px;
        }
        span {
          @include enBold;
          @include mq(tb) {
            display: none;
          }
        }
      }
      p, ul {
        @include fs(14,28);
        padding-bottom: .75em;
        @include mq(tbl) {
          @include fs(16,32);
          padding-bottom: 1.5em;
        }
      }
      ul {
        li {
          margin-left: 1em;
          text-indent: -1em;
          &:before {
            content: '\30FB';
          }
        }
      }
    }
    .en {
      @include mq(tb) {
        @include flex(1);
        padding-left: 15px;
      }
      @include mq(tbl) {
        padding-left: 25px;
      }
      @include mq(pc) {
        padding-left: 50px;
      }
      h2 {
        display: none;
        border-bottom: 2px solid $main;
        @include jaBold;
        padding: 0 0 5px;
        @include fs(13,21);
        margin-bottom: 15px;
        @include mq(tb) {
          display: block;
          @include fs(14,21);
        }
        @include mq(tbl) {
          @include fs(15,21);
          padding: 0 0 6px;
          margin-bottom: 18px;
        }
        @include mq(pc) {
          @include fs(16,21);
          padding: 0 0 7px;
        }
        span {
          @include enBold;
        }
      }
      p, ul {
        @include en;
        @include fs(13,28);
        padding-bottom: .75em;
        @include mq(tbl) {
          @include fs(15,32);
          padding-bottom: 1.5em;
        }
      }
      ul {
        li {
          margin-left: 1em;
          text-indent: -1em;
          &:before {
            content: ' - ';
          }
        }
      }
    }
  }
}
.m_map {
  padding: 0 $ps 50px;
  margin-top: 30px;
  @include mq(tbl) {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    margin-top: 60px;
  }
  @include mq(pc) {
    padding: 0 0 100px;
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
  #map {
    width: 100%;
    height: 50vw;
    @include mq(pc) {
      height: 580px;
    }
  }
  .access {
    margin-top: 30px;
    @include mq(tb) {
      @include flexbox;
    }
    @include mq(tbl) {
      margin-top: 45px;
    }
    @include mq(pc) {
      margin-top: 60px;
      padding: 0 50px;
    }
    .ja {
      @include mq(tb) {
        @include flex(1);
        padding-right: 15px;
      }
      @include mq(tbl) {
        padding-right: 25px;
      }
      @include mq(pc) {
        padding-right: 50px;
      }
      p, address {
        @include fs(14,28);
        padding-bottom: .75em;
        @include mq(tbl) {
          @include fs(16,32);
          padding-bottom: 1.5em;
        }
      }
    }
    .en {
      @include mq(tb) {
        @include flex(1);
        padding-left: 15px;
      }
      @include mq(tbl) {
        padding-left: 25px;
      }
      @include mq(pc) {
        padding-left: 50px;
      }
      p, address {
        @include en;
        @include fs(13,28);
        padding-bottom: .75em;
        @include mq(tbl) {
          @include fs(15,32);
          padding-bottom: 1.75em;
        }
      }
    }
  }
}
