/* compiled from /scss/_modules.scss */

@charset "UTF-8";





@import "variables";
@import "mixins";
@import "flexbox";



.m_team {
  padding: 0 $ps;
  @include mq(tbl) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @include mq(pc) {
    @include container;
    padding: 0;
  }
  .unit {
    margin-bottom: 30px;
    @include mq(tbl) {
      margin-bottom: 80px;
      @include flexbox;
    }
    .photo {
      height: 50vw;
      max-width: 500px;
      max-height: 250px;
      background-position: center 15%;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 0 auto;
      $members: mouri_takafumi, mouri_nobuyuki, ito_akiko, irie_yusuke, nakanishi_kazumi, mukai_tatsuro, daisuke_yamamoto, yuhi_sakai, norihisa_murayama;
      @each $member in $members {
        &.#{$member} {
          background-image: url(#{$imagePath}#{$member}.jpg);
        }
      }
      @include mq(tbl) {
        width: 390px;
        height: 520px;
        background-position: center center;
        max-width: none;
        max-height: none;
      }
    }
    .text {
      margin-top: 30px;
      @include mq(tbl) {
        margin-top: 0;
        padding-left: 50px;
        @include flex(1);
      }
      .position {
        @include fs(13,26);
        text-align: center;
        @include mq(tbl) {
          @include fs(16,32);
          text-align: left;
        }
        span {
          @include en;
        }
      }
      h2 {
        text-align: center;
        margin-top: 10px;
        @include mq(tbl) {
          text-align: left;
          margin-top: 20px;
        }
        .ja {
          @include jaBold;
          @include fs(26,26);
          letter-spacing: 1px;
          @include mq(tbl) {
            @include fs(28,28);
            text-indent: -2px;
            letter-spacing: 2px;
          }
          @include mq(pc) {
            @include fs(32,32);
            letter-spacing: 4px;
          }
        }
        .en {
          @include enBold;
          text-transform: uppercase;
          color: $main;
          margin-top: 8px;
          @include fs(11,11);
          letter-spacing: 1px;
          @include mq(tbl) {
            @include fs(13,13);
            letter-spacing: 2px;
            margin-top: 10px;
          }
          @include mq(pc) {
            @include fs(14,14);
          }
        }
      }
      .profile {
        margin-top: 30px;
        .tab {
          @include flexbox;
          li {
            @include flex(1);
            padding: 15px;
            @include en;
            @include fs(12,12);
            background-color: #c4c4c4;
            color: white;
            letter-spacing: 1px;
            text-align: center;
            cursor: pointer;
            transition: background .2s ease, color .2s ease;
            @include mq(tbl) {
              @include fs(13,13);
            }
            &.active {
              background-color: $black;
            }
            &:hover {
              @include mq(tbl) {
                color: $black;
                &.active {
                  color: white;
                }
              }
            }
          }
        }
        .proftext {
          margin-top: 25px;
          .tab_area {
            display: none;
            &.active {
              display: block;
            }
            &.ja {
              p, ul {
                @include fs(14,28);
                padding-bottom: 1em;
                @include mq(tbl) {
                  @include fs(15,30);
                  padding-bottom: 1.5em;
                }
                @include mq(pc) {
                }
              }
            }
            &.en {
              p, ul {
                @include en;
                @include fs(13,26);
                padding-bottom: 1em;
                @include mq(tbl) {
                  @include fs(14,28);
                  padding-bottom: 1.5em;
                }
              }
            }
            ul {
              li {
                margin-left: 1em;
                text-indent: -1em;
                &:before {
                  content: '\30FB';
                }
                a {
                  text-decoration: underline;
                  &:hover {
                    @include mq(tbl) {
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .credit {
    text-align: center;
    padding: 10px 0 50px;
    @include fs(12,24);
    letter-spacing: 1px;
    @include mq(tbl) {
      padding: 20px 0 100px;
      @include fs(13,26);
    }
  }
}
