/* compiled from /scss/_modules.scss */

@charset "UTF-8";





@import "variables";
@import "mixins";
@import "flexbox";



.m_blocks {
  padding: 0 $ps;
  @include mq(tbl) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @include mq(pc) {
    @include container;
    padding: 0;
  }
  .unit {
    padding-bottom: 30px;
    @include mq(tbl) {
      @include flexbox;
      padding-bottom: 80px;
    }
    .title {
      background-color: $main;
      padding: 16px 20px 18px;
      @include mq(tbl) {
        width: 300px;
        padding: 30px;
      }
      @include mq(pc) {
        width: 350px;
      }
      &.wide {
        @include mq(tbl) {
          width: 350px;
        }
        @include mq(pc) {
          width: 500px;
        }
      }
      &.wide02 {
        @include mq(pc) {
          width: 500px;
        }
      }
      .ja {
        color: $white;
        @include jaBold;
        @include fs(18,24);
        letter-spacing: 1px;
        @include mq(tbl) {
          @include fs(30,38);
        }
        @include mq(pc) {
          @include fs(40,50);
          letter-spacing: 2px;
        }
        &.narrow {
          @include mq(tbl) {
            line-height: 1;
            padding-bottom: 5px;
          }
          @include mq(pc) {
            padding-bottom: 10px;
          }
        }
      }
      .en {
        @include enBold;
        @include fs(12,18);
        letter-spacing: 1px;
        @include mq(tbl) {
          @include fs(16,22);
          letter-spacing: 2px;
          margin-top: 5px;
        }
        @include mq(pc) {
          @include fs(20,28);
          letter-spacing: 3px;
        }
        &.narrow {
          @include mq(tbl) {
            line-height: 1.2;
          }
        }
      }
      span {
        font-size: .8em;
        @include mq(tbl) {
          font-size: .6em;
        }
      }
    }
    .desc {
      margin-top: 30px;
      padding: 0 15px;
      @include mq(tbl) {
        margin-top: 0;
        @include flex(1);
        padding: 30px 0 15px;
        padding-left: 50px;
      }
      @include mq(pc) {
        padding-left: 75px;
      }
      .lead {
        .ja {
          @include fs(14,28);
          padding-bottom: 1em;
          @include mq(tbl) {
            @include fs(16,32);
            padding-bottom: 1.5em;
          }
          @include mq(pc) {
            @include fs(18,36);
          }
        }
        .en {
          @include en;
          @include fs(13,26);
          padding-bottom: 1em;
          @include mq(tbl) {
            @include fs(15,30);
            padding-bottom: 1.5em;
          }
          @include mq(pc) {
            @include fs(17,34);
          }
        }
      }
      .cost {
        border: 2px solid #e5e5e5;
        padding: 25px;
        margin: 5px 0 15px;
        @include mq(tbl) {
          border-width: 4px;
          padding: 35px;
          margin: 10px 0 15px;
        }
        h3 {
          @include jaBold;
          @include mq(tbl) {
            @include fs(16,32);
          }
          @include mq(pc) {
            @include fs(18,36);
          }
          span {
            font-size: 1.1em;
            line-height: 1.6;
            @include mq(tbl) {
              font-size: 1.2em;
            }
          }
        }
        ul {
          margin-top: 15px;
          li {
            @include fs(13,24);
            margin-left: 1em;
            text-indent: -1em;
            @include mq(tbl) {
              @include fs(14,26);
            }
          }
        }
      }
      .flow {
        ol {
          li {
            @include flexbox;
            &:last-child {
              h3 {
                &:before {
                  content: none;
                }
              }
            }
            h3 {
              @include enBold;
              @include fs(12,12);
              white-space: nowrap;
              width: 80px;
              padding-top: 4px;
              position: relative;
              @include mq(tbl) {
                @include fs(14,14);
                width: 105px;
              }
              @include mq(pc) {
                @include fs(16,16);
              }
              &:after {
                content: '';
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $main;
                position: absolute;
                right: 10px;
                top: 5px;
                @include mq(tbl) {
                  width: 16px;
                  height: 16px;
                  right: 18px;
                  top: 4px;
                }
                @include mq(pc) {
                  top: 6px;
                }
              }
              &:before {
                content: '';
                display: block;
                width: 2px;
                height: 100%;
                background-color: $main;
                position: absolute;
                right: 15px;
                top: 5px;
                @include mq(tbl) {
                  right: 25px;
                }
                @include mq(pc) {
                  top: 7px;
                }
              }
              span {
                font-size: 1.2em;
              }
            }
            .detail {
              @include flex(1);
              padding-bottom: 25px;
              @include mq(tbl) {
                padding-bottom: 35px;
              }
              h4 {
                padding-bottom: 1em;
                .ja {
                  @include jaBold;
                  @include fs(15,21);
                  @include mq(tbl) {
                    @include fs(18,24);
                  }
                  @include mq(pc) {
                    @include fs(20,28);
                  }
                }
                .en {
                  @include en;
                  @include fs(12,18);
                  @include mq(tbl) {
                    @include fs(13,21);
                    letter-spacing: 1px;
                  }
                  @include mq(pc) {
                    @include fs(14,22);
                  }
                }
              }
              p {
                @include fs(13,23);
                @include mq(tbl) {
                  @include fs(15,28);
                }
                @include mq(pc) {
                  @include fs(16,32);
                }
              }
            }
          }
        }
      }
      .note {
        border: 2px solid #e5e5e5;
        padding: 20px;
        margin: 5px 0 15px;
        text-align: center;
        @include mq(tbl) {
          border-width: 4px;
          padding: 25px;
          margin: 15px 0 15px;
        }
        h4 {
          @include jaBold;
          @include fs(15,25);
          @include mq(tbl) {
            @include fs(18,32);
            letter-spacing: 1px;
          }
        }
        p {
          margin-top: 1em;
          @include en;
          @include fs(13,24);
          @include mq(tbl) {
            @include fs(14,26);
          }
          @include mq(pc) {
            @include fs(15,28);
          }
        }
      }
    }
    &.reverse {
      @include mq(tbl) {
        @include flex-direction(row-reverse);
      }
      .desc {
        @include mq(tbl) {
          padding-right: 50px;
          padding-left: 0;
        }
        @include mq(pc) {
          padding-right: 75px;
        }
      }
    }
  }
}
