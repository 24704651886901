/* compiled from /scss/_modules.scss */

@charset "UTF-8";


@import "variables";
@import "mixins";
@import "flexbox";

@mixin athlete_title {
  text-align: center;
  span {
    color: #563E95;
    display: block;
    @include enBold;
    @include fs(14,14);
    @include mq(tbl) {
      @include fs(20,20);
    }
  }
  h2 {
    margin-top: 10px;
    @include jaBold;
    @include fs(28,40);
    @include mq(tbl) {
      margin-top: 20px;
      @include fs(38,46);
    }
  }
}
:root {
	--magic-number: calc(tan(7deg)/2); /*  */
	--content-width: 100vw;
	--skew-padding: calc(var(--content-width) * var(--magic-number));
}

.m_athlete_mv {
  background: no-repeat right bottom / 95% url(/images/athlete_mv_bg.svg) ;
  padding-bottom: 60px;
  text-align: right;
  @include mq(tbl) {
    padding-bottom: 140px;
    background-size: 80%;
  }
  .image {
    @include mq(tbl) {
      padding-right: 7%;
    }
    img {

    }
  }
  h1 {
    letter-spacing: .05em;
    @include jaBold;
    color: $white;
    margin-top: 20px;
    text-align: right;
    padding-right: 20px;
    @include fs(18,24);
    position: relative;
    padding-left: 20px;
    display: inline-block;
    @include mq(tbl) {
      @include fs(23,26);
      padding-right: 8%;
      margin-top: 25px;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba($color: #ffffff, $alpha: .4);
      @include mq(tbl) {
        bottom: -3px;
        height: auto;
      }
    }
  }
}

.m_athlete_intro {
  letter-spacing: .06em;
  padding: 0 $ps;
  margin-top: 30px;
  @include mq(tbl) {
    padding: 0;
    margin-top: 0;
  }
  .inner {
    @include mq(tbl) {
      @include container;
    }
    h2 {
      @include fs(28,44);
      @include jaBold;
      @include mq(tbl) {
        @include fs(43,66);
        padding-left: 45px;
      }
      strong {
        color: $main;
      }
    }
    p {
      margin-top: 50px;
      padding-bottom: 35px;
      @include fs(13,24);
      @include jaBold;
      text-align: right;
      background: no-repeat right bottom / 284px url(/images/athlete_bg_01.svg) ;
      @include mq(tbl) {
        @include fs(16,36);
        margin-top: -50px;
        padding: 0 50px 50px 0;
        background-size: 330px auto;
      }
    }
    figure {
      margin-top: 10px;
      text-align: center;
      @include mq(tbl) {
        margin-top: 80px;
      }
      img {
        max-width: 340px;
        @include mq(tbl) {
          max-width: 957px;
        }
      }
    }
  }
}
.m_athlete_service {
  position: relative;
  letter-spacing: .05em;
  margin-top: 50px;
  padding-top: var(--skew-padding);
  @include mq(tbl) {
    margin-top: 130px;
  }
  &::before {
    z-index: 1;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #F2F2F2;
    transform: skewY(7deg);
  }
  .inner {
    position: relative;
    z-index: 2;
    padding: 40px 20px 80px;
    @include mq(tbl) {
      @include container;
      padding-bottom: 150px;
    }
    .title {
      @include athlete_title;
    }
    .lead {
      @include fs(14,24);
      text-align: center;
      margin-top: 40px;
      @include mq(tbl) {
        @include fs(16,32);
        margin-top: 50px;
      }
    }
    .careers {
      text-align: center;
      @include mq(tbl) {
        display: flex;
        margin-top: 70px;
      }
      li {
        margin-top: 50px;
        @include mq(tbl) {
          flex: 1;
          margin-top: 0;
        }
        .image {
          img {
            max-width: 350px;
            transform: translateX(-10px);
            @include mq(tbl) {
              max-width: none;
            }
          }
        }
        h3 {
          @include enBold;
          @include fs(14,14);
          @include mq(tbl) {
            @include fs(22,22);
          }
          strong {
            color: #563E95;
            @include fs(22,28);
            @include mq(tbl) {
              @include fs(34,42);
            }
          }
        }
        p {
          @include jaBold;
          @include fs(14,30);
          margin-top: 10px;
          @include mq(tbl) {
            margin-top: 30px;
            @include fs(16,32);
          }
        }
      }
    }
    .merits {
      @include mq(tbl) {
        display: flex;
        margin-top: 50px;
        padding: 0 20px;
        gap: 20px;
        transform: translateX(80px);
      }
      .unit {
        margin-top: 20px;
        @include mq(tbl) {
          margin-top: 0;
          flex: 1;
        }
        .title {
          display: flex;
          align-items: flex-end;
          gap: 20px;
          img {
            height: 137px;
            width: auto;
          }
          h4 {
            @include jaBold;
            @include fs(16,16);
            position: relative;
            padding-bottom: 9px;
            padding-left: 6px;
            transform: translateY(-20px);
            flex: 1;
            text-align: left;
            &::after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              height: 20px;
              background-color: #EBEA23;
              width: 100%;
              z-index: -1;
              @include mq(tbl) {
                width: 280px;
              }
            }
            @include mq(tbl) {
              width: 280px;
            }
            strong {
              @include fs(22,22);
            }
          }
        }
        ul {
          margin-top: 20px;
          @include mq(tbl) {
            margin-left: 60px;
          }
          li {
            @include fs(16,24);
            margin-top: 1em;
            padding-left: 40px;
            position: relative;
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 3px;
              left: 10px;
              width: 19px;
              height: 19px;
              background: no-repeat center / contain url(/images/icon_check.svg) ;
              z-index: 0;
            }
            &:first-child {
              margin-top: 0;
            }
            span {
              @include fs(13,21);
            }
          }
        }
      }
    }
  }
}
.m_athlete_interview {
  position: relative;
  letter-spacing: .05em;
  margin-top: calc(-1 * var(--skew-padding));
  padding-top: calc(2 * var(--skew-padding));
  background: no-repeat left / cover url(/images/athlete_interview_bg_02.png) ;
  @include mq(tbl) {
    background-position: bottom center;

    padding-bottom: calc(2 * var(--skew-padding));
  }
  &::after {
    z-index: 1;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  &::after {
    mix-blend-mode: multiply;
    transform: skewY(7deg);
  }
  .inner {
    position: relative;
    z-index: 2;
    padding: 20px 20px 180px;
    color: $white;
    @include mq(tbl) {
      @include container;
      padding: 40px 0;
    }
    .title {
      @include athlete_title;
      text-align: left;
      @include mq(tbl) {
        padding-left: 48%;
      }
      span {
        color: #EBEA23;
      }
      h2 {
        color: $white;
      }
    }
    .lead {
      @include fs(14,28);
      margin-top: 35px;
      @include mq(tbl) {
        @include fs(16,32);
        margin-top: 65px;
        padding-left: 53%;
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: calc(53% - 48px);
          top: 17px;
          height: 1px;
          width: 30px;
          background-color: rgba($color: #ffffff, $alpha: .4);
        }
      }
      p {
        padding-bottom: 1em;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
    .button {
      &.pc {
        display: none;
        @include mq(tbl) {
          display: block;
          margin-top: 35px;
        }
      }
      &.sp {
        margin-top: 50px;
        @include mq(tbl) {
          display: none;
        }
      }
      a {
        color: #563E95;
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;
        @include jaBold;
        max-width: 260px;
        padding: 14px 20px 16px 24px;
        background-color: $white;
        border-radius: 30px;
        margin: 0 auto;
        @include fs(16,16);
        @include mq(tbl) {
          margin: 0 0 0 auto;
          transition: transform .3s ease-in-out;
          padding: 19px 25px 21px 30px;
        }
        &:hover {
          @include mq(tbl) {
            transform: translateY(-4px);
          }
        }
        i {
          img {
            width: 22px;
            @include mq(tbl) {
            }
          }
        }
        span {
          flex: 1;
          padding-left: 15px;
          border-left: 1px solid #E0E0E0;
        }
      }
    }
    .profile {
      margin-top: 40px;
      display: flex;
      gap: 30px;
      @include mq(tbl) {
        margin-top: 45px;
        gap: 55px;
        padding-left: 33%;
      }
      .photo {
        width: 110px;
        @include mq(tbl) {
          width: 160px;
        }
      }
      .text {
        flex: 1;
        h3 {
          @include fs(22,40);
          @include enBold;
          @include mq(tbl) {
            @include fs(28,42);
          }
        }
        .en {
          img {
            width: 90px;
            @include mq(tbl) {
              margin-top: 5px;
              width: 120px;
            }
          }
        }
        ul {
          margin-top: 20px;
          @include mq(tbl) {
            margin-top: 30px;
          }
          li {
            margin-top: 10px;
            @include fs(11,16);
            @include mq(tbl) {
              @include fs(12,20);
            }
          }
        }
      }
    }
  }
}
.m_athlete_flow {
  margin-top: -20px;
  @include mq(tbl) {
    margin-top: 40px;
  }
  .inner {
    padding: 0 20px;
    @include mq(tbl) {
      padding: 0 80px;
      @include container;
    }
    .title {
      @include athlete_title;
      @include mq(tbl) {
        text-align: left;
      }
    }
    .steps {
      margin-top: 60px;
      letter-spacing: 0.05em;
      @include mq(tbl) {
        margin-top: 70px;
      }
      .unit {
        position: relative;
        padding: 0 0 40px 0;
        @include mq(tbl) {
          padding-bottom: 60px;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 20px;
          width: 1px;
          height: 100%;
          background-color: #563E95;
        }
        &:last-child {
          padding-bottom: 0;
          &::before {
            display: none;
          }
        }
        i {
          display: block;
          background-color: #EBEA23;
          padding: 6px 0 4px;
          width: 150px;
          text-align: center;
          @include enBold;
          @include fs(14,14);
          position: relative;
          z-index: 2;
          @include mq(tbl) {
            @include fs(16,16);
            padding: 8px 0 7px;
          }
        }
        h3 {
          padding-left: 50px;
          margin-top: 30px;
          @include fs(20,30);
          @include jaBold;
          color: #563E95;
          @include mq(tbl) {
            @include fs(26,36);
            padding-left: 70px;
          }
        }
        p {
          padding-left: 50px;
          margin-top: 20px;
          @include fs(14,28);
          @include mq(tbl) {
            @include fs(16,32);
            padding-left: 70px;
          }
          a{
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.m_athlete_contact {
  background-color: #563E95;
  margin-top: 65px;
  padding: 45px 0 50px;
  @include mq(tbl) {
    margin-top: 170px;
    padding: 100px 0 110px;
  }
  .inner {
    letter-spacing: 0.05em;
    padding: 0 20px;
    @include mq(tbl) {
      padding: 0;
      @include container;
    }
    .title {
      @include athlete_title;
      span {
        color: #EBEA23;
      }
      h2 {
        color: $white;
      }
      p {
        margin-top: 30px;
        color: $white;
        @include fs(14,28);
        @include mq(tbl) {
          @include fs(16,30);
        }
      }
    }
    ul {
      margin-top: 40px;
      @include mq(tbl) {
        display: flex;
        justify-content: center;
        margin-top: 70px;
        gap: 40px;
      }
      li {
        margin-top: 28px;
        @include mq(tbl) {
          margin-top: 0;
        }
        a {
          color:#563E95;
          display: block;
          position: relative;
          @include jaBold;
          max-width: 305px;
          padding: 19px 25px 21px 35px;
          background-color: $white;
          border-radius: 30px;
          margin: 0 auto;
          @include fs(17,17);
          filter: drop-shadow(0px 10px 16px rgba(0, 0, 0, 0.25));
          @include mq(tbl) {
            padding: 22px 25px 24px 30px;
            @include fs(19,19);
            width: 305px;
          }
          &:hover {
            @include mq(tbl) {
              i {
                transform: translateX(4px);
              }
            }
          }
          i {
            display: inline-block;
            margin-left: 10px;
            position: absolute;
            right: 25px;
            transition: transform .3s ease;
            @include mq(tbl) {
              right: 25px;
            }
            img {
              width: 9px;
              transform: translateY(-1px);
              @include mq(tbl) {
                width: 8px;
                transform: translateY(-1px);
              }
            }
          }
        }
      }
    }
  }
}
.m_athlete_support {
  margin-top: 50px;
  padding: 0 0 80px;
  @include mq(tbl) {
    margin-top: 110px;
    padding-bottom: 120px;
  }
  .inner {
    letter-spacing: 0.05em;
    padding: 0 20px;
    @include mq(tbl) {
      @include container;
      display: flex;
      align-items: center;
    }
    .text {
      @include mq(tbl) {
        flex: 1;
        padding-left: 122px;
      }
      h2 {
        @include fs(18,32);
        text-align: center;
        @include jaBold;
        @include mq(tbl) {
          text-align: left;
          @include fs(24,46);
        }
      }
    }
    .photo {
      margin-top: 25px;
      display: flex;
      gap: 12px;
      @include mq(tbl) {
        flex: 1;
      }
      li {
        flex: 1;
      }
    }
    .button {
      &.pc {
        display: none;
        @include mq(tbl) {
          display: block;
          margin-top: 35px;
        }
      }
      &.sp {
        margin-top: 50px;
        @include mq(tbl) {
          display: none;
        }
      }
      a {
        color: $white;
        display: block;
        position: relative;
        @include jaBold;
        max-width: 260px;
        padding: 19px 25px 21px 30px;
        background-color: #563E95;
        border-radius: 30px;
        margin: 0 auto;
        @include fs(16,16);
        @include mq(tbl) {
          margin: 0;
          padding: 19px 25px 21px 30px;
        }
        &:hover {
          @include mq(tbl) {
            i {
              transform: translateX(4px);
            }
          }
        }

        i {
          display: inline-block;
          margin-left: 10px;
          position: absolute;
          right: 20px;
          transition: transform .3s ease;
          @include mq(tbl) {
            right: 25px;
          }
          img {
            width: 7px;
            transform: translateY(-1px);
            @include mq(tbl) {
              width: 8px;
              transform: translateY(-1px);
            }
          }
        }
      }
    }
  }
}
/*
.m_athlete_outline {
  padding: 0 $ps 30px;
  margin-top: 30px;
  @include mq(tbl) {
    padding: 0;
    margin-top: 50px;
  }
  .inner {
    @include mq(tbl) {
      @include container;
    }
    p {
      @include fs(14,28);
      @include mq(tbl) {
        @include fs(16,32);
      }
      strong {
        @include jaBold;
      }
    }
    figure {
      margin-top: 30px;
      text-align: center;
      padding-bottom: 20px;
      @include mq(tbl) {
        margin-top: 50px;
      }
      img {
        &.sp {
          width: 100%;
          max-width: 350px;
          @include mq(tb) {
            display: none;
          }
        }
        &.pc {
          max-width: 975px;
          display: none;
          @include mq(tb) {
            display: inline-block;
          }
        }
      }
    }
    .kamesennin {
      margin-top: 30px;
      border: 2px solid #E5E5E5;
      padding: 20px;
      @include mq(tbl) {
        display: flex;
        gap: 40px;
        padding: 35px;
        align-items: center;
      }
      .photo {
        display: none;
        text-align: center;
        @include mq(tbl) {
          width: 280px;
        }
        img {
          max-width: 280px;
        }
      }
      .desc {
        // margin-top: 20px;
        @include mq(tbl) {
          flex: 1;
          margin-top: 0;
        }
        .position {
          @include jaBold;
          @include fs(10,15);
          @include mq(tbl) {
            @include fs(12,18);
          }
        }
        .name {
          @include jaBold;
          @include fs(18,28);
          @include mq(tbl) {
            @include fs(20,30);
          }
        }
        .profile {
          margin-top: 5px;
          @include fs(13,22);
          @include mq(tbl) {
            margin-top: 20px;
            @include fs(14,24);
          }
        }
      }
    }
  }
}
.m_athlete_framework {
  padding: 0 $ps;
  margin-top: 30px;
  @include mq(tbl) {
    padding: 0;
    margin-top: 50px;
  }
  .inner {
    @include mq(tbl) {
      @include container;
    }
    > p {
      @include fs(14,24);
      @include mq(tbl) {
        @include fs(16,28);
      }
    }
    .figure {
      border: 1px solid #000;
      margin-top: 20px;
      @include mq(tbl) {
        margin-top: 40px;
      }
      .top {
        padding: 20px;
        @include mq(tbl) {
          padding: 45px 40px;
          display: flex;
        }
        .unit {
          &:last-child {
            margin-top: -50px;
            @include mq(tbl) {
              margin-top: 0;
            }
          }
          .title {
            text-align: center;
            h3 {
              @include jaBold;
              @include fs(14,28);
              @include mq(tbl) {
                @include fs(20,28);
              }
              &.solutions {
                color: $main;
              }
              strong {
                @include fs(18,28);
                @include mq(tbl) {
                  @include fs(24,28);
                }
              }
            }
            p {
              @include en;
              @include fs(11,14);
              color: #C1C1C1;
              letter-spacing: .1em;
              @include mq(tbl) {
                @include enBold;
                @include fs(14,21);
              }
            }
          }
          .issues {
            margin-top: 20px;
            @include mq(tbl) {
              margin-top: 25px;
            }
            li {
              border: 3px solid #E5E5E5;
              border-radius: 20px;
              margin-top: 10px;
              padding: 15px 20px;
              @include fs(13,24);
              @include mq(tbl) {
                border-width: 5px;
                border-radius: 25px;
                @include fs(14,24);
                aspect-ratio: 473 / 111;
                padding: 32px;
                width: 473px;
              }
              strong {
                @include jaBold;
              }
            }
          }
          ul.solutions {
            margin-top: 20px;
            @include mq(tbl) {
              margin-top: 25px;
            }
            li {
              border: 3px solid $main;
              border-radius: 20px;
              margin-top: 10px;
              padding: 15px 20px;
              @include fs(14,24);
              @include mq(tbl) {
                border-width: 5px;
                border-radius: 25px;
                @include fs(16,28);
                aspect-ratio: 469 / 170;
                padding: 32px;
                width: 469px;
              }
              strong {
                @include jaBold;
              }
            }
          }
        }
        .arrow {
          text-align: center;
          margin-top: -30px;
          @include mq(tbl) {
            margin-top: 0;
            align-self: center;
            padding: 70px 17px 0 26px;
          }
          img {
            transform-origin: center;
            transform: rotate(90deg);
            width: 30px;
            @include mq(tbl) {
              transform: rotate(0);
              width: 32px;
            }
          }
        }
      }
      .merit {
        background-color: #53CEC6;
        color: white;
        margin-top: 15px;
        text-align: center;
        padding: 30px 20px 20px;
        @include mq(tbl) {
          padding: 45px 40px;
        }
        h3 {
          @include jaBold;
          @include fs(14,24);
          @include mq(tbl) {
            @include fs(20,25);
          }
          strong {
            @include fs(18,24);
            @include mq(tbl) {
              @include fs(24,32);
            }
          }
        }
        ul {
          @include mq(tbl) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 45px;
          }
          li {
            color: black;
            margin-top: 15px;
            padding: 15px 0;
            position: relative;
            @include fs(14,24);
            @include mq(tbl) {
              @include fs(18,26);
              margin-top: 0;
              flex: 1;
            }
            span {
              @include fs(10,16);
              @include mq(tbl) {
                @include fs(12,15);
              }
            }
            &:first-child {
              margin-top: 5px;
              @include mq(tbl) {
                margin-top: 0;
              }
            }
            &:last-child {
              padding-bottom: 0;
              &::after {
                content: none;
              }
            }
            &:after {
              content: '';
              display: block;
              height: 1px;
              width: 60px;
              background: white;
              position: absolute;
              left: 50%;
              bottom: 0;
              transform: rotate(-15deg) translateX(-50%);
              @include mq(tbl) {
                height: 80px;
                width: 1px;
                transform: rotate(15deg) translate(-10px,-50%);
                right: 0;
                left: auto;
                top: 50%;
                bottom: auto;
              }
            }
            strong {
              @include jaBold;
            }
          }
        }
      }
    }
    
  }
}
*/