/* compiled from /scss/_modules.scss */

@charset "UTF-8";





@import "variables";
@import "mixins";
@import "flexbox";



.m_contactform {
  padding: 0 $ps 50px;
  text-align: left;
  @include mq(tbl) {
    padding: 0 50px 100px;
  }
  @include mq(pc) {
    padding: 0 0 120px;
    @include container;
  }
  .inner {
    @include mq(tbl) {
      @include flexbox;
    }
    .lead {
      @include mq(tbl) {
        @include flex(1);
        margin-right: 20px;
      }
      h2 {
        border-bottom: 2px solid $main;
        margin-top: 20px;
        @include jaBold;
        padding: 0 0 4px;
        @include fs(14,21);
        margin-bottom: 15px;
        @include mq(tb) {
          @include fs(15,21);
        }
        @include mq(tbl) {
          @include fs(16,21);
          padding: 0 0 6px;
          margin-bottom: 18px;
        }
        @include mq(pc) {
          @include fs(17,21);
          padding: 0 0 8px;
        }
        span {
          @include enBold;
        }
      }
      .ja {
        @include fs(14,28);
        padding-bottom: .75em;
        @include mq(tbl) {
          @include fs(16,32);
          padding-bottom: 1.5em;
        }
      }
      .en {
        @include en;
        @include fs(13,28);
        padding-bottom: .75em;
        @include mq(tbl) {
          @include fs(15,32);
          padding-bottom: 1.75em;
        }
      }
    }
    .form {
      border: 2px solid $black;
      margin-top: 25px;
      padding: 25px;
      @include mq(tbl) {
        @include flex(1);
        margin-left: 25px;
        padding: 40px;
      }
      h2 {
        @include fs(14,21);
        margin-bottom: 5px;
        @include mq(tb) {
          @include fs(15,21);
        }
        @include mq(tbl) {
          @include fs(16,21);
          margin-bottom: 12px;
        }
        @include mq(pc) {
          @include fs(17,21);
        }
        span {
          @include en;
        }
      }
      p {
        padding-bottom: 15px;
        @include mq(tbl) {
          padding-bottom: 25px;
        }
        input, textarea {
          width: 100%;
          border: none;
          background-color: #e6e6e6;
          padding: 8px;
          @include fs(14,21);
        }
        textarea {
          height: 150px;
          @include mq(tbl) {
            height: 250px;
          }
        }
      }
      button {
        margin-top: 5px;
        width: 100%;
        @include mq(tbl) {
          max-width: none;
        }
      }
    }
  }
}

.m_contact_list {
  padding-bottom: 100px;
  @include mq(tbl) {
    padding-bottom: 160px;
  }
  .unit {
    margin-top: 80px;
    @include mq(tbl) {
      margin-top: 120px;
    }
    &:first-child {
      margin-top: 30px;
      @include mq(tbl) {
        margin-top: 50px;
      }
    }
    .title {
      padding: 0 20px;
      letter-spacing: .05em;
      text-align: center;
      @include mq(tbl) {
        text-align: left;
        @include container;
        padding: 0;
      }
      .ja {
        @include jaBold;
        padding: 0 0 4px;
        @include fs(22,21);
        letter-spacing: .1em;
        @include mq(tbl) {
          @include fs(36,36);
        }
      }
      .en {
        @include enBold;
        color: #B5B5B5;
        @include fs(13,13);
        margin-top: 8px;
        @include mq(tbl) {
          @include fs(18,18);
          margin-top: 14px;
        }
      }
    }
    .inner {
      margin-top: 30px;
      letter-spacing: .05em;
      @include mq(tbl) {
        display: flex;
        justify-content: space-between;
        gap: 4%;
        @include container;
        margin-top: 50px;
      }
      .image {
        @include mq(tbl) {
          width: 48%;
        }
      }
      .text {
        padding: 0 20px;
        margin-top: 30px;
        @include mq(tbl) {
          padding: 0;
          margin-top: 0;
          width: 48%;
        }
        .ja {
          @include fs(14,28);
          padding-bottom: .75em;
          @include mq(tbl) {
            @include fs(16,32);
            padding-bottom: 1.5em;
          }
        }
        .en {
          @include en;
          @include fs(13,28);
          padding-bottom: .75em;
          @include mq(tbl) {
            @include fs(15,32);
            padding-bottom: 1.75em;
          }
        }
        .button {
          margin-top: 20px;
          a {
            color: $white;
            display: block;
            position: relative;
            @include jaBold;
            max-width: 260px;
            padding: 14px 20px 16px 20px;
            background-color: $main;
            border-radius: 30px;
            margin: 0 auto;
            @include fs(16,16);
            @include mq(tbl) {
              margin: 0 0 0 auto;
              padding: 19px 25px 21px 30px;
            }
            &:hover {
              @include mq(tbl) {
                i {
                  transform: translateX(4px);
                }
              }
            }

            i {
              display: inline-block;
              margin-left: 10px;
              position: absolute;
              right: 20px;
              transition: transform .3s ease;
              @include mq(tbl) {
                right: 25px;
              }
              img {
                width: 7px;
                transform: translateY(-1px);
                @include mq(tbl) {
                  width: 8px;
                  transform: translateY(-1px);
                }
              }
            }
          }
        }
      }
    }
  }
}

.m_contact_form {
  margin-top: 50px;
  padding-bottom: 100px;
  @include mq(tbl) {
    margin-top: 90px;
    padding-bottom: 160px;
  }
  .inner {
    padding: 0 20px;
    @include mq(tbl) {
      @include container;
      padding: 0;
    }
    .lead {
      @include mq(tbl) {
        text-align: center;
      }
      .ja {
        @include fs(14,28);
        padding-bottom: .75em;
        @include mq(tbl) {
          @include fs(16,32);
          padding-bottom: 1.5em;
        }
      }
      .en {
        @include en;
        @include fs(13,28);
        padding-bottom: .75em;
        @include mq(tbl) {
          @include fs(15,32);
          padding-bottom: 1.75em;
        }
      }
    }
    .form {
      margin-top: 50px;
      @include mq(tbl) {
        max-width: 670px;
        margin: 100px auto 0;
      }
      h2 {
        margin-top: 20px;
        @include fs(14,24);
        @include jaBold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mq(tbl) {
          margin-top: 50px;
          letter-spacing: .05em;
          @include fs(18,24);
        }
        span {
          display: inline-block;
          @include fs(10,16);
          background-color: #B2B2B2;
          color: $white;
          padding: 2px 10px;
          white-space: nowrap;
          @include mq(tbl) {
            @include fs(12,18);
            padding: 2px 10px 3px;
          }
          &.required {
            background-color: #F86F6F;
          }
        }
      }
      p {
        margin-top: 10px;
        input, textarea {
          width: 100%;
          border: none;
          background-color: #F2F2F2;
          padding: 8px;
          @include fs(16,24);
          @include mq(tbl) {
            padding: 14px;
          }
          &.wpcf7-not-valid {
            background-color: #f8e9e9;
          }
        }
        textarea {
          height: 150px;
          @include mq(tbl) {
            height: 250px;
          }
        }
      }
      button {
        color: $white;
        display: block;
        position: relative;
        @include jaBold;
        width: 260px;
        padding: 18px 30px 20px 30px;
        background-color: #F86F6F;
        border-radius: 30px;
        margin: 30px auto 0;
        @include fs(14,14);
        @include mq(tbl) {
          @include fs(18,18);
          margin-top: 60px;
          width: 360px;
          border-radius: 40px;
          padding: 24px 30px 26px 30px;
        }
        &:hover {
          @include mq(tbl) {
            i {
              transform: translateX(4px);
            }
          }
        }

        i {
          display: inline-block;
          margin-left: 10px;
          position: absolute;
          right: 20px;
          transition: transform .3s ease;
          @include mq(tbl) {
            right: 25px;
          }
          img {
            width: 7px;
            transform: translateY(-1px);
            @include mq(tbl) {
              width: 8px;
              transform: translateY(-1px);
            }
          }
        }
      }
      .terms {
        margin-top: 30px;
        @include mq(tbl) {
          margin-top: 60px;
        }
        label {
          display: block;
          text-align: center;
          input {
            display: inline-block;
            vertical-align: -1px;
            width: auto;
            margin-right: 10px;
          }
          a {
            text-decoration: underline;
            color: $main;
          }
        }
      }
    }
  }
}
.m_contact_thanks {
  margin-top: 30px;
  padding-bottom: 60px;
  @include mq(tbl) {
    margin-top: 30px;
    padding-bottom: 120px;
  }
  .inner {
    padding: 0 20px;
    @include mq(tbl) {
      @include container;
    }
    h2 {
      text-align: center;
      letter-spacing: .05em;
      .ja {
        @include jaBold;
        padding: 0 0 4px;
        @include fs(18,21);
        letter-spacing: .1em;
        display: block;
        @include mq(tbl) {
          @include fs(22,21);
        }
      }
      .en {
        @include en;
        @include fs(13,13);
        margin-top: 8px;
        display: block;
        @include mq(tbl) {
          @include fs(16,18);
          margin-top: 14px;
        }
      }
    }
    .text {
      margin-top: 30px;
      @include mq(tb) {
        text-align: center;
        margin-top: 50px;
      }
      .ja {
        @include fs(14,28);
        padding-bottom: .75em;
        @include mq(tbl) {
          @include fs(16,32);
          padding-bottom: 1.5em;
        }
      }
      .en {
        @include en;
        @include fs(13,28);
        padding-bottom: .75em;
        @include mq(tbl) {
          @include fs(15,32);
          padding-bottom: 1.75em;
        }
      }
    }
  }
}