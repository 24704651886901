@import "variables";

@mixin mq($breakpoint: tb) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin jaThin {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 300;
}
@mixin ja {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
}
@mixin jaBold {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 600;
}
@mixin enThin {
  font-family: roboto,sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin en {
  font-family: roboto,sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin enBold {
  font-family: roboto,sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin container {
  width: $container;
  margin-right: auto;
  margin-left: auto;
}
@mixin hoverOp($op:0.5) {
  transition: opacity .2s linear;
  -webkit-backface-visibility: hidden;
  &:hover {
    opacity: $op;
    text-decoration: inherit;
  }
}
@mixin hide-text() {
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin fs($fts, $lin: 14) {
  $rem: $fts / 10;
  $linS: $lin / $fts;
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
}

@mixin slickdots($width:10px,$height:10px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 8px;
    button {
      border: none;
      background-color: #b2b2b2;
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      @include border-radius(5px);
    }
    &.slick-active {
      button {
        background-color: #4d4d4d;
      }
    }
  }
}
