/* compiled from /scss/_modules.scss */

@charset "UTF-8";





@import "variables";
@import "mixins";
@import "flexbox";



header {
  height: 55px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 999;
  @include mq(tbl) {
    position: static;
    height: auto;
  }
  .inner {
    padding: 10px 20px 0;
    @include mq(tbl) {
      @include flexbox;
      @include align-items(center);
      @include justify-content(space-between);
      padding: 18px 20px 25px 30px;
    }
    @include mq(pc) {
      padding: 18px 20px 25px 30px;
      max-width: 1300px;
      margin: 0 auto;
    }
    #logo {
      @include flexbox;
      @include align-items(center);
      h1 {
        a {
          width: 82px;
          height: 28px;
          background-image: url(/images/logo.png);
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          text-indent: 100%;
          white-space: nowrap;
          overflow: hidden;
          @include mq(tbl) {
            width: 141px;
            height: 48px;
            background-image: url(/images/logo_pc.png);
          }
        }
      }
      p {
        @include jaBold;
        @include fs(9,9);
        margin: 8px 0 0 10px;
        letter-spacing: 1px;
        transform: scale(.9);
        @include mq(tbl) {
          @include fs(12,12);
          margin: 10px 0 0 25px;
          transform: scale(1);
        }
        @include mq(pc) {
          @include fs(14,14);
          margin-left: 30px;
        }
        span {
          transition: color .5s ease;
        }
      }
      &:hover {
        p {
          span {
            color: $main;
          }
        }
      }
    }
    #gnav {
      display: none;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 55px;
      width: 100%;
      height: 100%;
      padding-bottom: 55px;
      background-color: white;
      z-index: 1000;
      overflow-y: auto;
      -webkit-overflow-scrolling:touch;
      @include mq(tbl) {
        display: block;
        position: static;
        width: auto;
        height: auto;
        z-index: 1;
        padding-bottom: 0;
        overflow-y: auto;
        padding-top: 10px;
      }
      &.show {
        display: block;
        background-color: $main;
      }
      ul {
        @include mq(tbl) {
          @include flexbox;
          @include align-items(center);
          margin-top: 3px;
        }
        li {
          border-top: 1px solid #eee;
          @include mq(tbl) {
            border-top: none;
          }
          a {
            display: block;
            padding: 12px 15px;
            background-color: white;
            position: relative;
            @include mq(tbl) {
              text-align: center;
              padding: 10px 12px 15px;
            }
            @include mq(pc) {
              padding: 10px 15px 15px;
            }
            &:after {
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              background-color: $main;
              height: 3px;
              left: 10px;
              right: 8px;
              transform: scaleX(0);
              transition: transform .2s ease;
              @include mq(pc) {
                left: 12px;
                right: 12px;
              }
            }
            &:hover {
              @include mq(tbl) {
                strong {
                  color: $main;
                }
                span {
                  color: #aaa;
                }
                &:after {
                  transform: scale(1);
                }
              }
            }
            &.active {
              @include mq(tbl) {
              }
            }
            strong {
              @include fs(14,14);
              display: block;
              margin: 0 auto;
              @include jaBold;
              transition: color .2s ease;
              @include mq(pc) {
                @include fs(15,15);
              }
            }
            span {
              @include en;
              @include fs(9,9);
              display: block;
              margin: 7px auto 0;
              letter-spacing: 1px;
              color: #666;
              transition: color .2s ease;
              @include mq(tbl) {
                margin-top: 10px;
                transform: scale(.9);
              }
              @include mq(pc) {
                transform: scale(1);
              }
            }
          }
          &.sns {
            background-color: white;
            display: flex;
            justify-content: center;
            padding: 15px 0;
            a {
              display: flex;
              justify-content: center;
              align-items: center;
              @include hoverOp;
              border-radius: 15px;
              width: 30px;
              height: 30px;
              margin: 0 10px;
              padding: 0;
              &.twitter {
                background-color: #1da1f2;
                img {
                  width: 17px;
                  height: auto;
                }
              }
              &.note {
                background-color: #41c9b4;
                img {
                  width: 14px;
                  height: auto;
                }
              }
            }
            @include mq(tbl) {
              display: none;
            }
          }
        }
      }
    }
  }
}
#menuButton {
	position: absolute;
  top: 20px;
	right: 18px;
	width: 20px;
	height: 14px;
	cursor: pointer;
	z-index: 9999;
  @include mq(tbl) {
    display: none;
  }
  .top & {
    position: fixed;
  }
  div {
		position: absolute;
		right: 0;
		width: 20px;
		height: 2px;
		background: $black;
		-webkit-transition: -webkit-transform .2s ease, background .2s ease .2s, top .2s ease .2s;
		transition: transform .2s ease, background .2s ease .2s, top .2s ease .2s;
		&.line01 {
			top: 0;
		}
		&.line02 {
			top: 6px;
		}
		&.line03 {
			top: 12px;
		}
	}
	&.show {
		div {
			-webkit-transition: background .2s ease, top .2s ease, -webkit-transform .2s ease .2s;
			transition: background .2s ease, top .2s ease, transform .2s ease .2s;
			background: $black;
			&.line01 {
				top: 6px;
				-webkit-transform: rotateZ(45deg);
				transform: rotateZ(45deg);
			}
			&.line02 {
				background: rgba(255, 255, 255, 0) !important;
			}
			&.line03 {
				top: 6px;
				-webkit-transform: rotateZ(-45deg);
				transform: rotateZ(-45deg);
			}
		}
	}
}
#snsButton {
  display: none;
  position: fixed;
  left: 27px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 200;
  transition: opacity 1s ease-out 1.2s;
  @include mq(tbl){
    display: block;
  }
  li {
    margin: 15px 0;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      @include hoverOp;
      border-radius: 18px;
      width: 36px;
      height: 36px;
      &.twitter {
        background-color: #1da1f2;
        img {
          width: 17px;
          height: auto;
        }
      }
      &.note {
        background-color: #41c9b4;
        img {
          width: 14px;
          height: auto;
        }
      }
    }
  }
}
